<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1>
          Relatório de Documentos por empresa
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="nome">Empresa</label>
          <select2 
            :ItemsProp="empresas"
            :FieldProp="'nome_fantasia'"
            :PlaceholderTextProp="'Pesquisar empresa'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="empresaSelecionada"
            @Selected="EmpresaSelecionada($event)">  
          </select2>
        </div>
      </div>
      <div class="mr-5 align-self-end">        
        <div class="form-group">
          <button type="button" class="btn btn-primary" @click="gerarRelatorio">GERAR</button>
        </div>
      </div>
    </div>
    <div  class="row mt-5"  v-if="dataItems.length > 0">
      <div class="col-12 mb-3 text-right d-flex justify-content-end align-items-center">
        <!-- <Checkbox @checkOption="agruparDocumento" :valueProp="documentos.agrupar" class="mr-3"></Checkbox>
        <span class="pl-2 mr-3">Agrupar</span> -->
        <button type="button" class="btn btn-primary" @click="gerarPdf">Gerar PDF</button>
      </div>
      <div id="printtable" class="col-12">
        <table class=" mt-5 table table-hover border table-striped table-bordered">
          <thead>
            <td class="w-25">Marque para gerar o QR Code</td>
            <td>Nome do documento</td>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataItems" :key="index">
              <td class="align-middle">
                <Checkbox @checkOption="checkItem" :valueProp="item" ></Checkbox>
              </td>
              <td>{{item.nome_arquivo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import empresaDocumentosRepository from '../../services/api/empresaDocumentosRepository'
import empresaRepository from '../../services/api/empresaRepository'
import select2 from './../../uicomponents/select2'
import Checkbox from './../../uicomponents/Checkbox'
export default {
  components: {
    select2,
    Checkbox
  },
  data () {
    return {
      empresas: [],
      empresaSelecionada: null,
      documentos: {
        ids: [],
        agrupar: false
      },
      documentosSelecionados: [],
      dataItems: []
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      try {
        this.$store.commit("setSplashScreen", true)
        let responseE = await empresaRepository.listarUnidades()
        this.$store.commit("setSplashScreen", false)
        if(responseE.data['success']) {
          this.empresas = responseE.data['data']
        }
      }catch (e) {
        console.log(e)
        this.$store.commit("setSplashScreen", false)
        this.$swal({
          icon: 'error',
          text: 'Não foi possível buscar os dados básicos de filtro!'
        })
      }finally {
        this.$store.commit("setSplashScreen", false)
      }
    },
    EmpresaSelecionada(empresa) {
      this.empresaSelecionada = empresa
    },
    gerarRelatorio() {
      if(!this.empresaSelecionada) {
        this.$swal({
          icon: 'warning',
          text: 'Selecione a empresa!'
        })
        return
      }
      this.$store.commit("setSplashScreen", true)
      empresaDocumentosRepository.listarDocumentosPorEmpresaId(this.empresaSelecionada.id).then(response => {
        this.$store.commit("setSplashScreen", false)
        this.dataItems = response.data.data
      }).catch(() => {
        this.$store.commit("setSplashScreen", false)
        this.$swal({
          icon: 'error',
          text: 'Ocorreu um erro ao gerar o relatório!'
        })
      })
    },
    checkItem(checked, item) {
      if (checked) {
        this.documentos.ids.push(item.id)
      } else {
        let documentoIndex = this.documentos.ids.findIndex(f => f == item.id)
        console.log(documentoIndex)
        this.documentos.ids.splice(documentoIndex, 1)
      }
    },
    agruparDocumento(checked) {
      this.documentos.agrupar = checked
    },
    gerarPdf() {
      console.log(this.documentos)
      this.$store.commit("setSplashScreen", true)
      empresaDocumentosRepository.gerarPdf(this.documentos).then(response => {
        this.$store.commit("setSplashScreen", false)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'documentos.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error.response)
        this.$store.commit("setSplashScreen", false)
      })
    }
  }
}
</script>

<style>

</style>